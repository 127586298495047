import React, { useEffect, useState, useRef } from 'react';
import css from './TutorCard.module.scss';
import { Icon,Row,Col } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ITutorProfileListItem } from 'types';
import {  Text } from 'rebass';
import StarRating from 'components/StarRating';
import blankUser from 'static/img/blank_profile.png';
import { currency, isUAE } from 'utils/env';
import { priceForExperience } from 'utils';
import cn from 'classnames';
import labelCss from 'styles/modules/labels-with-icon.module.scss';
interface Props {
    tutor:ITutorProfileListItem
    viewTutor:(tutor: ITutorProfileListItem,clickType:string)=>void
}



export default function TutorCardNew(props: Props) {
  const {tutor,viewTutor}=props
  const tutorPhoto = tutor.photo_card || blankUser;
  const pricePerHour = priceForExperience(tutor.selected_subject.experience);
  const { t } = useTranslation()
  const getFeaturedOrRating = () => (
    tutor.num_reviews > 0 && !!tutor.rating && (
      <Text fontSize={[0, 1]}>
        <StarRating
          rating={tutor.rating}
          fontSize={0.85}
          distanceBetweenStars={5}
          color={'#FFA704'}
          score={tutor.num_reviews || 0}
          scoreColor="#62686E"
        />
      </Text>
    )
  );

  return (
   <div className={css.tutorCarWrap} onClick={(e)=>{
    e.stopPropagation()
    viewTutor(tutor,'')
   }}>
    <div className={css.leftContent}
       style={{backgroundImage: `url(${tutorPhoto})`}}
    >
        <div className={css.priceStar}>
          <div className={css.priceHour}>
            <span className={css.priceText}>{currency}{pricePerHour * 1.05}</span>
            <span className={css.smailText}>/ {t('hour')}</span>
          </div>
          {!!tutor.rating&&<span className={css.startText} >
              <Icon
              style={{ color: '#FFA703'} }
              type="star"
              theme="filled"
              />
               {tutor.rating}
          </span>}
        </div>
    </div>
    <div className={css.rightContent}>
        <div className={css.name}>{tutor.name}</div>
        <div className={css.description}>{tutor.tagline||''}</div>
        <Row className={css.tagRow}>
            {!!tutor.experience_years&&<Col span={12} className={css.tagColum}>
              <span className={css.countLabelNumber}>{tutor.experience_years * 50}+</span>
              <span className={css.text}> {t('Lessons')}</span>
            </Col>}
            {!!tutor.experience_years&&<Col span={12} className={css.tagColum}>
              <span style={{paddingLeft:"26px"}} className={labelCss.exp}></span>
              <span className={css.text}>{t('Exp')} {tutor.experience_years} {t('years')}</span>
            </Col>}
            <Col span={12} className={css.tagColum}>
              <span style={{paddingLeft:"26px"}} className={labelCss.pinLabelTwo}></span>
              <span className={css.text}>{t('Online')}</span>
            </Col>
            <Col span={12} className={css.tagColum}>
              <span style={{paddingLeft:"26px"}} className={labelCss.mortarPinTwo}></span>
              <span className={css.text}>{t('Degree')}</span>
            </Col>
            <Col  span={12} className={css.tagColum}>
              <span style={{paddingLeft:"26px"}} className={labelCss.verified}></span>
              <span className={css.text}>{t('Verified')}</span>
            </Col>
            {!!tutor.is_dbs_checked&&<Col span={12} className={css.tagColum}>
              <span style={{paddingLeft:"26px"}} className={labelCss.check}></span>
              <span className={css.text}> {t('DBS')}</span>
            </Col>}
        </Row>
        <div className={css.operate}>
            <div className={css.operateItem} onClick={(e) => {
              e.stopPropagation();
              viewTutor(tutor,'share')
            }}>
              <span className={cn(css.operateItemIcon,css.share)}></span>
              <span>{t('Share')}</span>
            </div>
            <div className={css.operateItem} onClick={(e) => {
              e.stopPropagation();
              viewTutor(tutor,'message')
            }}>
              <span className={cn(css.operateItemIcon,css.messages)}></span>
              <span>{t('Message')}</span>
              </div>
            <div className={css.operateItem} onClick={(e) => {
              e.stopPropagation();
              viewTutor(tutor,'book')
            }}>
              <span className={cn(css.operateItemIcon,css.book)}></span>
              <span>{t('Book')}</span>
            </div>
        </div>
    </div>
   </div>
  );
}
